
@font-face {
  font-family: 'BAESystems_Light';
  src: url('../Web_Fonts/BAESystems-Light.eot');
  src: url('../Web_Fonts/BAESystems-Light.eot?#iefix') format('embedded-opentype'),
       url('../Web_Fonts/BAESystems-Light.woff') format('woff'),
       url('../Web_Fonts/BAESystems-Light.ttf') format('truetype'),
       url('../Web_Fonts/BAESystems-Light.svg#webfontregular') format('svg');
  font-weight:normal;
  font-style:normal;

}

@font-face {
  font-family: 'BAESystems_Regular';
  src: url('../Web_Fonts/BAESystems-Regular.eot');
  src: url('../Web_Fonts/BAESystems-Regular.eot?#iefix') format('embedded-opentype'),
       url('../Web_Fonts/BAESystems-Regular.woff') format('woff'),
       url('../Web_Fonts/BAESystems-Regular.ttf') format('truetype'),
       url('../Web_Fonts/BAESystems-Regular.svg#webfontregular') format('svg');
  font-weight:normal;
  font-style:normal;

}

@font-face {
  font-family: 'BAESystems_Bold';
  src: url('../Web_Fonts/BAESystems-Bold.eot');
  src: url('../Web_Fonts/BAESystems-Bold.eot?#iefix') format('embedded-opentype'),
       url('../Web_Fonts/BAESystems-Bold.woff') format('woff'),
       url('../Web_Fonts/BAESystems-Bold.ttf') format('truetype'),
       url('../Web_Fonts/BAESystems-Bold.svg#webfontregular') format('svg');
  font-weight:normal;
  font-style:normal;

}


.sup_cap_bg {
  background-image: url('../../../public/sup_cap_bg.jpg');
  background-repeat: no-repeat;
  background-size: auto;
  /*height: 100vh;*/
}

.black-bg {
  background: #3d3d3d;
}

.fighter-bg {
  background-image: url('https://fjspares.regional-services.com/img/portal_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

body {
  background: #dedede;
  counter-reset: section;
}

.home-bg {
  background: #dedede;
  /*height: 150vh;*/
}

.padding-both {
  padding-top: 1em;
  padding-bottom: 1em;
}

.padding-top {
  padding-top: 0.4rem;
}

.padding-top-large {
  padding-top: 1.5em;
}

.margin-top {
  margin-top: 0.4rem;
}

.margin-top-large {
  margin-top: 1.5em;
}



.orange {
  color: #fc4c02;
}
/*--Topbar--*/

.top-bar{
  background-color: #394954 !important;
}

.topbar-responsive {
  background: #2c3840;
  padding: 1rem 1.5rem;
}

  .topbar-responsive .topbar-responsive-logo {
      color: #fefefe;
      vertical-align: middle;
  }

  .topbar-responsive .menu {
      background: #2c3840;
  }

      .topbar-responsive .menu li:last-of-type {
          margin-right: 0;
      }

      .topbar-responsive .menu a {
          color: #fefefe;
          transition: color 0.15s ease-in;
      }

          .topbar-responsive .menu a:hover {
              color: #c6d1d8;
          }

@media screen and (max-width: 39.9375em) {
  .topbar-responsive .menu a {
      padding: 0.875rem 0;
  }
}

.topbar-responsive .menu .topbar-responsive-button {
  color: #fefefe;
  border-color: #fefefe;
  border-radius: 5000px;
  transition: color 0.15s ease-in, border-color 0.15s ease-in;
}

  .topbar-responsive .menu .topbar-responsive-button:hover {
      color: #c6d1d8;
      border-color: #c6d1d8;
  }

@media screen and (max-width: 39.9375em) {
  .topbar-responsive .menu .topbar-responsive-button {
      width: 100%;
      margin: 0.875rem 0;
  }
}

@media screen and (max-width: 39.9375em) {
  
  .topbar-responsive {
      padding: 0.75rem;
  }

      .topbar-responsive .top-bar-title {
          position: relative;
          width: 100%;
      }

          .topbar-responsive .top-bar-title span {
              position: absolute;
              right: 0;
              border: 1px solid #fefefe;
              border-radius: 5px;
              padding: 0.25rem 0.45rem;
              top: 50%;
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              transform: translateY(-50%);
          }

              .topbar-responsive .top-bar-title span .menu-icon {
                  margin-bottom: 4px;
              }
}

@-webkit-keyframes fadeIn {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}

@-webkit-keyframes slideDown {
  0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
  }

  100% {
      -webkit-transform: translateY(0%);
      transform: translateY(0%);
  }
}

@keyframes slideDown {
  0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
  }

  100% {
      -webkit-transform: translateY(0%);
      transform: translateY(0%);
  }
}

@media screen and (max-width: 39.9375em) {
  .topbar-responsive-links {
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;
      width: 100%;
      -webkit-animation: fadeIn 1s ease-in;
      animation: fadeIn 1s ease-in;
  }
}

.topbar-responsive-logo {
  font-family: "BAESystems-Light", Helvetica, Roboto, Arial, sans-serif;
  font-size: 42px; /*22px*/
  color: #636363;
  border-left: 15px solid #FC3C02;
  padding-left: 15px;
}

  .topbar-responsive-logo span {
      color: #fc4c02;
  }

img.header-logo {
  width: 200px;
  height: 30px;
}

.circle {
  width: 20px;
  border-radius: 250px;
  font-size: 1em;
  color: #fff;
  /*line-height:100px;*/
  text-align: center;
  background: #FC3C02;
  position: absolute;
  bottom: 1.3em;
  left: 1.8em;
}

  .circle a {
      color: #EAEAEA;
  }
/*--end of topbar--*/ 
/*--Checkout Breadcrumbs--*/
.breadcrumb-counter-nav {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  list-style: none;
  background: #2C3E50;
}

.breadcrumb-counter-nav .breadcrumb-counter-nav-item {
  position: relative;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding: 1rem 2rem;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

  .breadcrumb-counter-nav .breadcrumb-counter-nav-item:hover {
      background: #1a252f;
  }

  .breadcrumb-counter-nav .breadcrumb-counter-nav-item::before {
      counter-increment: section;
      content: counter(section);
      position: relative;
      background: #507192;
      color: #fff;
      right: 0.8rem;
      border-radius: 50%;
      padding: 0.2em 0.55em;
      font-weight: bold;
  }


  .breadcrumb-counter-nav .breadcrumb-counter-nav-item.current {
      background: #FC4C02;
  }

      .breadcrumb-counter-nav .breadcrumb-counter-nav-item.current::after {
          content: '';
          position: absolute;
          top: 0;
          right: -1rem;
          width: 0;
          height: 0;
          border-top: 1.75rem solid transparent;
          border-bottom: 1.75rem solid transparent;
          border-left: 1rem solid #FC4C02;
          z-index: 300;
      }

      .breadcrumb-counter-nav .breadcrumb-counter-nav-item.current::before {
          background-color: #ffffff;
          color: #507192;
      }

  .breadcrumb-counter-nav .breadcrumb-counter-nav-item a {
      color: #fff;
      font-size: 0.875rem;
  }

  .breadcrumbs a {
    font-size: 0.875rem;
  }

@media only screen and (max-width: 52em) {
  .breadcrumb-counter-nav {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
  }
}
/*--end of checkout breadcrumbs--*/

/*--headers--*/
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "BAESystems_Bold", Helvetica, Roboto, Arial, sans-serif !important;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}

/*--links--*/
a {
  font-weight: bold;
}

.button {
  border-radius: 5000px;
}

/*preloader*/
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #333 url('https://fjspares.regional-services.com/img/loading.gif') no-repeat center center;
  visibility: hidden;
}

.show_preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #333 url('https://fjspares.regional-services.com/img/loading.gif') no-repeat center center;
  opacity: 0.5;
  visibility: visible !important;
}




/*--progress--*/


.progress-indicator {
  list-style: none;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: table;
  table-layout: fixed;
}

  .progress-indicator > li {
      position: relative;
      display: table-cell;
      text-align: center;
      font-size: 1.5em;
  }

      .progress-indicator > li span {
          position: absolute;
          color: #e6e6e6;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          /*font-weight: 600;*/
          font-size: 0.775rem;
          /*letter-spacing: 0.05px;
text-transform: uppercase;*/
      }

          .progress-indicator > li span span.date {
              font-size: 0.575rem;
          }

      .progress-indicator > li::before {
          content: attr(data-step);
          display: block;
          margin: 0 auto;
          background: #e6e6e6;
          width: 1.5em;
          height: 1.5em;
          text-align: center;
          margin-bottom: 0.25em;
          line-height: 1.5em;
          border-radius: 100%;
          position: relative;
          z-index: 1000;
      }

      .progress-indicator > li::after {
          content: '';
          position: absolute;
          display: block;
          background: #e6e6e6;
          width: 100%;
          height: 0.15em;
          top: 50%;
          -webkit-transform: translateY(-100%);
          -ms-transform: translateY(-100%);
          transform: translateY(-100%);
          left: 50%;
          margin-left: 1.5em\9;
          z-index: 0;
      }

      .progress-indicator > li:last-child:after {
          display: none;
      }

      .progress-indicator > li.is-complete {
          color: #1779ba;
      }

          .progress-indicator > li.is-complete::before, .progress-indicator > li.is-complete::after {
              color: #fefefe;
              background: #1779ba;
          }

          .progress-indicator > li.is-complete span {
              color: #1779ba;
          }

      .progress-indicator > li.is-current {
          /*color: #4eabe9;*/
          color: #fc4c0b;
      }

          .progress-indicator > li.is-current::before {
              color: #fefefe;
              /*background: #4eabe9;*/
              background: #fc4c0b;
          }

          .progress-indicator > li.is-current span {
              /*color: #4eabe9;*/
              color: #fc4c0b;
          }

/*--subtle alerts--*/

.alert-callout-subtle {
  width: 100%;
  background: #e6e6e6;
  color: #8a8a8a;
  border: 0;
  border-left: 0.3125rem solid #8a8a8a;
  box-shadow: 0 5px 8px -6px rgba(0, 0, 0, 0.2);
}

  .alert-callout-subtle.success {
      background: #e6e6e6;
      color: #8a8a8a;
      border-left: 0.3125rem solid #3adb76;
  }

  .alert-callout-subtle.alert {
      background: #e6e6e6;
      color: #8a8a8a;
      border-left: 0.3125rem solid #cc4b37;
  }

  .alert-callout-subtle.warning {
      background: #e6e6e6;
      color: #8a8a8a;
      border-left: 0.3125rem solid #ffae00;
  }

  .alert-callout-subtle.primary {
      background: #e6e6e6;
      color: #8a8a8a;
      border-left: 0.3125rem solid #1779ba;
  }

  .alert-callout-subtle.small {
      font-size: 80%;
  }

      .alert-callout-subtle.small .close-button {
          font-size: 1.5rem;
      }

  .alert-callout-subtle.large {
      font-size: 150%;
  }

      .alert-callout-subtle.large .close-button {
          font-size: 3rem;
      }

  .alert-callout-subtle .close-button {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  }

  .alert-callout-subtle.radius {
      border-radius: 0.6rem;
  }

/*notes*/

.testimonial-block-vertical-quote {
  background: rgba(23, 121, 186, 0.25);
  padding: 1rem;
  border-radius: 0;
  margin-bottom: 1.875rem;
  position: relative;
}

  .testimonial-block-vertical-quote p {
      margin-bottom: 0;
  }

  .testimonial-block-vertical-quote::after {
      content: '';
      bottom: -1.25rem;
      left: 2.25rem;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 1.25rem 1.125rem 0 1.125rem;
      border-color: rgba(23, 121, 186, 0.25) transparent transparent transparent;
      position: absolute;
  }

.testimonial-block-vertical-person {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1.65rem;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-block-vertical-avatar {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.testimonial-block-vertical-name {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 0.65rem;
}

.testimonial-block-vertical-info {
  text-transform: uppercase;
  font-size: 14px;
  color: #8a8a8a;
  margin-bottom: 0;
  margin-left: 0.65rem;
}

/*tables*/
thead {
  font-size: 12px;
  color: #969695;
  font-weight: normal;
  vertical-align:top !important;
}

tr {
  color: #969695;
  font-size: 12px;
}

  tr.to_vet {
      border-left: 4px solid red;
  }

  tr.vetted {
      border-left: 4px solid green;
  }

  tr.completed {
      border-left: 4px solid gray;
  }

/*--subfooter--*/
.subfooter {
  color: #ffffff;
  background-color: grey;
}

/*--Canvas--*/
canvas.orderChart {
  width: 100% !important;
  height: 260px !important;
}

/*--callout--*/

.callout {
  -webkit-box-shadow: 4px 4px 3px 0.5px #ADADAD;
  box-shadow: 4px 4px 3px 0.5px #ADADAD;
  border: none;
  height:500px;
  overflow-y:scroll;
}

/*--Tabs--*/

.tabs-title > a{
  font-family: "BAESystems_Normal", Helvetica, Roboto, Arial, sans-serif !important;
  font-style: normal;
  font-weight: normal;
  color: #fc4c02 !important;
  text-rendering: optimizeLegibility;
}

.tabs-title > a .is-active{
  font-family: "BAESystems_Bold", Helvetica, Roboto, Arial, sans-serif !important;
}

#tab-container{
  -webkit-box-shadow: 5px 5px 2px .3px #B5B5B5;
box-shadow: 5px 5px 2px .3px #B5B5B5;
}
.tabs{
  -webkit-border-radius: 10px 10px 0 0;
border-radius: 10px 10px 0 0;
}

.tabs-content{
  -webkit-border-radius: 0 0 10px 10px;
border-radius: 0 0 10px 10px;
}

.tabs-panel {
  min-height: 300px;
}

/*--footer--*/

.footer-container{
  background: #394954;
  color:#ffffff;
  min-height:200px;

}

.footer-container a{
  color:#ffffff;
}

footer{
  width:80%;
  margin-left:auto;
  margin-right:auto;
  padding-top:2em;
  color:#ffffff;
}

footer ul li a{
  color:#808181;
}

footer ul li a:hover {
  color:#ffffff;
}

span.address {
  color:#808181
}

section.sub-footer{
  color:#808181;
}

/*--tabs--*/

.tabs-title  a{
  font-size: 1.15rem !important;
  font-family: "BAESystems_Regular", Helvetica, Roboto, Arial, sans-serif !important;
}

/*--icons --*/

.large-icon {
  font-size:1.275rem;
}

/*--main container --*/

.main_container {
  padding:2em;
  background-color: #edf0f2;
}

/*--list group sidebar --*/

.sidebar {
  background: #394954;
  /*height: 150vh;*/
}

.list-group {
  margin-bottom: 1rem;
  background: #394954;
  box-shadow: none;
  overflow: hidden;
  color: #ffffff;
  margin-left:0px;
}

.list-group > :last-child {
  margin-bottom: 0;
}

.list-group-item {
  font-family: "BAESystems_Regular", Helvetica, Roboto, Arial, sans-serif;
  padding: 1.5rem;
  font-size:1.3em;
}

.list-group-item > :last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.list-group-item.active {
  color: #fefefe;
  background-color: #606e78;
}

.list-group-item:hover, .list-group-item:focus {
  background-color: #606e78;
}

.list-group-item:hover.active, .list-group-item:focus.active {
  background-color: #606e78;
}

.list-group-item.disabled, .list-group-item.disabled:hover, .list-group-item.disabled:focus, .list-group-item[disabled], .list-group-item[disabled]:hover, .list-group-item[disabled]:focus {
  color: #8a8a8a;
  cursor: not-allowed;
  background-color: #fefefe;
}

/*--end of lost group sidebar--*/

/*-- download buttons --*/
 
.button, .btn41-43 {
  padding: 10px 15px;
  font-family: 'Helvetica Neue','Helvetica','Roboto','Arial','sans-serif';
  font-weight: 500;
  background: #14679E;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.btn-41 {
  border: 2px solid rgb(255, 255, 255);
  z-index: 1;
  color: white;
}

.btn-41:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease;
}

.btn-41:hover {
  color: rgb(0, 0, 0);
}

.btn-41:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

.btn-41:active {
  top: 2px;
}